import HomePage from './pages/HomePage';
import BlogPage from './pages/BlogPage';
import BlogPost from './pages/BlogPost';
import AboutPage from "./pages/AboutPage";
import ForFitnessProf from "./pages/ForFitnessProf";
import ContentGuide from './pages/ContentGuide';
import Workbook from './pages/Workbook';
import Memberships from './pages/Memberships';
import Discover from './components/Discover';
import MembershipOptions from "./components/MembershipOptions";
import Resources from "./components/Resources";
import OurTrainers from './pages/OurTrainers';
import WorkoutForm from './pages/WorkOutFormPage'
import ClassesPage from './pages/ClassesPage';
import MemberBenefitsPage from './pages/MemberBenefitsPage';

import Form from './components/Form';
import Footer from './components/Footer';

import BlogContent1 from './blogContent/BlogContent1';
import BlogContent2 from './blogContent/BlogContent2';
import BlogContent3 from "./blogContent/BlogContent3";
import BlogContent4 from './blogContent/BlogContent4';
import BlogContent5 from "./blogContent/BlogContent5";
import BlogContent6 from "./blogContent/BlogContent6";
import BlogContent7 from './blogContent/BlogContent7';
import BlogContent8 from "./blogContent/BlogContent8";
import BlogContent9 from './blogContent/BlogContent9';
import BlogContent10 from "./blogContent/BlogContent10";
import BlogContent11 from "./blogContent/BlogContent11";
import BlogContent12 from './blogContent/BlogContent12';
import BlogContent13 from './blogContent/BlogContent13';
import BlogContent14 from './blogContent/BlogContent14';
import BlogContent15 from './blogContent/BlogContent15';
import BlogContent16 from "./blogContent/BlogContent16";
import BlogContent17 from './blogContent/BlogContent17';
import BlogContent18 from './blogContent/BlogContent18';
import BlogContent19 from "./blogContent/BlogContent19";
import BlogContent20 from "./blogContent/BlogContent20";
import BlogContent21 from './blogContent/BlogContent21';
import BlogContent22 from './blogContent/BlogContent22';
import BlogContent23 from './blogContent/BlogContent23';
import BlogContent24 from "./blogContent/BlogContent24";
import BlogContent25 from "./blogContent/BlogContent25";
import BlogContent26 from "./blogContent/BlogContent26";
import BlogContent27 from "./blogContent/BlogContent27";
import BlogContent28 from "./blogContent/BlogContent28";
import BlogContent29 from "./blogContent/BlogContent29";
import BlogContent30 from "./blogContent/BlogContent30";

import TrainerProfileTemplate from './pages/TrainerProfileTemplate';
import Kareem from "./components/trainerProfiles/Kareem"
import Tisha from './components/trainerProfiles/Tisha';
import Daniela from './components/trainerProfiles/Daniela';
import KD from './components/trainerProfiles/KD';

import { Routes, Route, Navigate} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import TagManager from 'react-gtm-module';
import { useEffect } from 'react';

function App() {

    useEffect(()=>{
        TagManager.initialize({ gtmId: "GTM-TQ5Q8LQ" });

    },[])


  return (
      <div className="App">
          <HelmetProvider>
              <Routes>
                  <Route path="*" element={<Navigate to="/" />} />
                  <Route path="/" element={<HomePage />} />
                  <Route path="/aboutus" element={<AboutPage />} />
                  <Route path="/blog" element={<BlogPage />} />
                  <Route path="/ourtrainers" element={<OurTrainers />} />
                  <Route path="/workoutform" element={<WorkoutForm />} />
                  <Route path="/classes" element={<ClassesPage />} />
                  <Route path="/memberbenefits" element={<MemberBenefitsPage />} />
                  <Route
                      path="/forfitnessprofessionals"
                      element={<ForFitnessProf />}
                  />
                  <Route
                      path="/forfitnessprofessionals/contentguide"
                      element={<ContentGuide />}
                  />
                  <Route
                      path="/forfitnessprofessionals/workbook"
                      element={<Workbook />}
                  />
                  <Route path="/memberships" element={<Memberships />} />

                  <Route path="/memberships" element={<Memberships />}>
                      <Route
                          path="membershipOptions"
                          element={<MembershipOptions />}
                      />
                  </Route>

                  <Route
                      path="/forfitnessprofessionals"
                      element={<ForFitnessProf />}
                  >
                      <Route path="resources" element={<Resources />} />
                  </Route>

                  <Route
                      path="/ourtrainers/trainer"
                      element={<TrainerProfileTemplate />}
                  >
                      <Route path="Kareem-Whyte" element={<Kareem />}></Route>
                      <Route path="Tisha-Henry" element={<Tisha />}></Route>
                      <Route path="Daniela" element={<Daniela />}></Route>
                      <Route path="KD" element={<KD />}></Route>
                  </Route>

                  <Route path="/blog/blogpost" element={<BlogPost />}>
                      <Route
                          path="grow-your-business"
                          element={<BlogContent1 />}
                      />
                      <Route
                          path="tips-for-fitness-trainers"
                          element={<BlogContent2 />}
                      />
                      <Route
                          path="key-takeaways-from-2022-canfitpro-survey"
                          element={<BlogContent3 />}
                      />
                      <Route
                          path="building-confidence-and-resilience-in-a-competitive-world"
                          element={<BlogContent4 />}
                      />
                      <Route
                          path="4-tips-to-start-a-fitness-business"
                          element={<BlogContent5 />}
                      />
                      <Route
                          path="4-best-tools-for-online-businesses"
                          element={<BlogContent6 />}
                      />
                      <Route
                          path="the-journey-to-full-time-personal-trainer"
                          element={<BlogContent7 />}
                      />
                      <Route
                          path="do-this-to-grow-your-business"
                          element={<BlogContent8 />}
                      />
                      <Route
                          path="movement-and-longevity"
                          element={<BlogContent9 />}
                      />
                      <Route
                          path="the-role-of-social-media"
                          element={<BlogContent10 />}
                      />
                      <Route
                          path="fitness-as-a-lifestyle"
                          element={<BlogContent11 />}
                      />
                      <Route
                          path="the-art-of-communication"
                          element={<BlogContent12 />}
                      />
                      <Route
                          path="you-are-never-too-young-to-follow-your-passions"
                          element={<BlogContent13 />}
                      />
                      <Route
                          path="we-know-you-are-doing-this-for-more-than-just-the-money"
                          element={<BlogContent14 />}
                      />
                      <Route
                          path="how-to-make-the-most-out-of-your-working-hours"
                          element={<BlogContent15 />}
                      />
                      <Route
                          path="the-two-communities-every-personal-trainer-must-build"
                          element={<BlogContent16 />}
                      />
                      <Route
                          path="the-business-side-of-client-acquisition"
                          element={<BlogContent17 />}
                      />
                      <Route
                          path="a-conversation-with-KD-about-life-and-basketball"
                          element={<BlogContent18 />}
                      />
                      <Route
                          path="Emily-Dugan-on-women-and-fitness"
                          element={<BlogContent19 />}
                      />
                      <Route
                          path="exceptional-customer-service"
                          element={<BlogContent20 />}
                      />
                      <Route
                          path="the-new-age-of-personal-training"
                          element={<BlogContent21 />}
                      />
                      <Route
                          path="are-there-too-many-personal-trainers"
                          element={<BlogContent22 />}
                      />
                      <Route
                          path="the-emotionally-intelligent-personal-trainer"
                          element={<BlogContent23 />}
                      />
                      <Route
                          path="social-media-for-personal-trainers"
                          element={<BlogContent24 />}
                      />
                      <Route
                          path="ola-on-body-image-fitness-goals-and-how-to-pick-a-trainer"
                          element={<BlogContent25 />}
                      />
                      <Route
                          path="fueling-your-fitness-business"
                          element={<BlogContent26 />}
                      />
                      <Route
                          path="navigating-the-payment-conversation"
                          element={<BlogContent27 />}
                      />
                      <Route
                          path="navigating-plateaus-and-setbacks-with-clients"
                          element={<BlogContent28 />}
                      />
                      <Route
                          path="using-wellness-trends-in-toronto-to-grow-your-personal-training-business"
                          element={<BlogContent29 />}
                      />
                      <Route
                          path="unlocking-your-first-client-success-stories"
                          element={<BlogContent30 />}
                      />
                  </Route>

                  <Route path="/" element={<HomePage />}>
                      <Route path="/form" element={<Form />} />
                      <Route path="/discover" element={<Discover />} />
                  </Route>
              </Routes>

              <Footer />
          </HelmetProvider>
      </div>
  );
}

export default App;
