import React from 'react'
import '../components/NavBar'
import NavBar from '../components/NavBar'
import "../styling/ClassesPage.scss"

function ClassesPage() {
  return (
      <div className="pltfrmClasses">
          <div className="wrapper">
              <NavBar />
              <h2>Classes coming soon!</h2>
          </div>
      </div>
  );
}

export default ClassesPage