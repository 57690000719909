import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes, faBars } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

import { useState } from "react";
import "../styling/NavBar.scss";

const NavBar =()=>{
    const [navClicked, setNavClicked] =useState(false)

    const navToggle =()=>{
        setNavClicked(!navClicked)
    }

    

    return (
        <nav className="navContainer">
            <div className="logoContainer">
                <Link to="/">
                    <div className="logo3"></div>
                </Link>
            </div>

            <ul className={navClicked ? "navLinks active" : "navLinks"}>
                <li className="siteNav">
                    <Link to="/forfitnessprofessionals" onClick={navToggle}>
                        For Fitness Professionals
                    </Link>
                </li>

                <li className="siteNav">
                    <Link to="/memberbenefits" onClick={navToggle}>
                        Member Benefits
                    </Link>
                </li>
                <li className="siteNav">
                    <Link to="/classes" onClick={navToggle}>
                        Classes
                    </Link>
                </li>

                <li className="siteNav">
                    <Link to="/memberships">
                        Memberships Options
                    </Link>
                </li>

                <li className="siteNav contactUs">
                    <a href="https://www.wellnessliving.com/login/pltfrm">
                        Member Login
                    </a>
                </li>
            </ul>

            <div className="menuIcon" onClick={navToggle}>
                {navClicked === true ? (
                    <FontAwesomeIcon icon={faTimes} />
                ) : (
                    <FontAwesomeIcon icon={faBars} />
                )}
            </div>
        </nav>
    );
}

export default NavBar;