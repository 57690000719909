import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import NavBar from "../components/NavBar";
import "../styling/Memberships.scss"
import MembershipOptions from "../components/MembershipOptions";

function Memberships() {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (location.pathname === "/memberships") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <div className="membershipsPage">
            <header className="membershipsHeader" ref={elementRef}>
                <div className="wrapper">
                    <NavBar />
                </div>
                <div className="membershipsHeaderContent">
                    <div className="wrapper">
                        <div className="membershipsHeaderText">
                            <h1>Become a member</h1>
                            <p>Be a part of a community</p>
                        </div>
                    </div>
                </div>
            </header>

            <section>
                <div className="wrapper membershipBenefits">
                    <h2>
                        Enjoy 24/7 Access to Toronto’s Premier Private{" "}
                        <span>Training Gym & Coworking Space</span>
                    </h2>
                    <ul className="benefitsContainer">
                        <li>
                            <div className="logoBlackCircle"></div>
                            <p>Weekly Group Classes</p>
                        </li>
                        <li>
                            <div className="logoBlackCircle"></div>
                            <p>Book Your Private Workout Alcove</p>
                        </li>
                        <li>
                            <div className="logoBlackCircle"></div>
                            <p>Access to Coworking & Lounge Spaces</p>
                        </li>
                        <li>
                            <div className="logoBlackCircle"></div>
                            <p>Onsite Showers & Changing Rooms</p>
                        </li>
                    </ul>
                </div>
            </section>

            <section className="membershipSection">
                <div className="wrapper">
                    <h2>
                        Our Memberships Gives you Unlimited Access to Book
                        <span>Private/Semi-Private Gym Spaces 24/7</span>
                    </h2>
                    <p>
                        You can book 1 training space per hour. You can book an
                        unlimited amount of spaces each month based on
                        availability.
                    </p>

                    <MembershipOptions />
                </div>
            </section>
            <section className="learnMoreAboutMemberships">
                <div className="wrapper">
                    <h3>Interested in learning more?</h3>
                    <p>
                        Email us at marketing@pltfrm.fit or book an in-person
                        tour
                    </p>
                    <button>
                        <a href="https://calendly.com/pltfrm-fit/30mintour">
                            Book Now
                        </a>
                    </button>
                </div>
            </section>
        </div>
    );
}

export default Memberships;
